import PageTitle from '../../components/pages/pageTitle'
import DoctorComp from '../../components/pages/doctorComp'
import Education from '../../components/pages/education'
import Work from '../../components/pages/work'
import { ProgressSpinner } from 'primereact/progressspinner'
import RandevuForm from '../../components/pages/randevuForm'
/* import WorkPhoto from '../../components/pages/workPhoto' */
import Address from '../../components/pages/address'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
const DoctorPage = () => {
    const { id } = useParams()
    const [isFetching, setIsFetching] = useState(false)
    const [doctorInfo, setDoctorInfo] = useState([])
    const getDoctorInfo = async () => {
        setIsFetching(true)
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/doctors?id=${id}`)
        setDoctorInfo(data?.data?.data)
        setTimeout(() => {
            setIsFetching(false)
        }, 1000)
    }
    useEffect(() => {
        getDoctorInfo()
    }, [id])
    return (
        <div>
            {
                isFetching ? (
                    <div className="flex mt-5 justify-content-center align-items-center" style={{ height: '70vh' }}>
                        <ProgressSpinner />
                    </div>
                ) :
                    (
                        <div style={{ position: 'relative' }}>
                            <PageTitle title='doctor_id' value={doctorInfo.name + ' ' + doctorInfo.surname} />
                            <DoctorComp doctor={doctorInfo} />
                            <Education education={doctorInfo.educations} />
                            <div className="work-comp">
                                <Work work={doctorInfo.works} />
                            </div>
                            <div className="comp-randevu">
                                <RandevuForm />
                            </div>
                            <Address />
                        </div>
                    )
            }
        </div>
    )
}

export default DoctorPage
