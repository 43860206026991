import './index.scss';
import { Galleria } from 'primereact/galleria';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const galleria = useRef(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/gallery`);
                setImages(response.data.data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
            } else if (e.key === 'ArrowLeft') {
                setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [images]);

    const itemTemplate = (item) => {
        return (
            <div className='gallery-item'>
                <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={`https://api.dental-mm.az/${item.photo}`} alt={item.title} />
                <div className='gallery-item-caption'>
                    <h5>{item.title}</h5>
                    <p>{item.body}</p>
                </div>
            </div>
        );
    };

    return (
        <div className='gallery-wrapper'>
            <Galleria
                ref={galleria}
                value={images}
                numVisible={7}
                style={{ maxWidth: '1024px' }}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                fullScreen
                showItemNavigators
                showThumbnails={false}
                item={itemTemplate}
            />

            <div className="grid" style={{ maxWidth: '1024px' }}>
                {
                    images && [...images].reverse().map((image, index) => {
                        return (
                            <div className="col-12 grid-item" key={index} onClick={() => { setActiveIndex(images.length - 1 - index); galleria.current.show(); }}>
                                <img src={`https://api.dental-mm.az/${image.photo}`} alt={image.title} />
                                <div className='gallery-item-caption'>
                                    <h5>{image.title}</h5>
                                    <p>{image.body}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Gallery;
