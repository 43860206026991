import './eduWork.scss'
const Education = ({info, education}) => {
    return (
        <div className="edu-work">
            <div className="title">{info}</div>
            <ul className="universities">
                {
                    education?.map((edu) => (
                        <li className="university">
                            <div className="year">{edu.years}</div>
                            <div className="place">{edu.body}</div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Education
