import './index.scss'
import {Carousel} from 'primereact/carousel'
import axios from 'axios'
import {useEffect, useState} from 'react'

const Hero = () => {
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ]
    const [image, setImage] = useState([])

    const itemTemplate = item => {
        return (
            <div className='hero-item'>
                <div className="right">
                    <img src={`https://api.dental-mm.az/${item?.photo}`} alt={item?.title} />
                </div>
                <div className="word">
                    <div className="title">{item?.title && item?.title}</div>
                    <div className="body">{item?.body && item?.body}
                    </div>
                </div>
            </div>
        )
    }

    const caruselPhoto = async() => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/carusel`)
        setImage(data?.data?.data)
    }
    useEffect(() => {
        caruselPhoto()
    }, [])

    return (
        <div className='hero' id="info">
            <Carousel
                value={image} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
                itemTemplate={itemTemplate}/>
        </div>
    )
}

export default Hero
