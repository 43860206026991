import PageTitle2 from '../../components/pages/pageTitle2'
import GalleryPhoto from '../../components/pages/gallery'
const Gallery = () => {
    return (
        <div>
            <PageTitle2 title='gallery' value='Qalereya'/>
            <GalleryPhoto />
        </div>
    )
}

export default Gallery
