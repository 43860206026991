import React from 'react'
const Address = () => {
    return (
        <div className='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d963.2759991099598!2d48.94176727084797!3d39.96116306635215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403b075ad81a3cd5%3A0xa71482f903c8f585!2sDENTAL-MM%20Stomatoloji%20Klinika!5e0!3m2!1str!2saz!4v1719061766058!5m2!1str!2saz"
                height="300px"
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Address
