import './contact.scss'
import {useEffect, useState} from 'react'
import axios from 'axios'
import Time from '../../../assets/images/time.svg'
import Phone from '../../../assets/images/phone.svg'
import Mail from '../../../assets/images/mail.svg'
import Address from '../../../assets/images/address.svg'
const ContactComp = () => {
    const [about, setAbout] = useState({})
    const aboutData = async() => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/about`)
        setAbout(data?.data?.data)
    }
    useEffect(() => {
        aboutData()
    }, [])
    return (
        <div className="contact-comp" >
            <div className="contact-card">
                <div className="image">
                    <img src={Time} alt=""/>
                </div>
                <div className="title">İş saatları</div>
                <div className="address">
                    <div className="work-date">
                        <div className="time">10:00 - 20:00</div>
                    </div>
                </div>
            </div>
            <div className="contact-card">
                <div className="image"><img src={Phone} alt=""/></div>
                <div className="title">Telefon</div>
                <div className="address">
                    {about?.phone?.split(',').map(elem => <div>{elem}</div>)}
                </div>
            </div>
            <div className="contact-card">
                <div className="image"><img src={Mail} alt=""/></div>
                <div className="title">Email</div>
                <div className="address">
                    {about?.email}
                </div>
            </div>
            <div className="contact-card">
                <div className="image"><img src={Address} alt=""/></div>
                <div className="title">Ünvan</div>
                <div className="address">
                    {about?.address}
                </div>
            </div>
        </div>
    )
}

export default ContactComp
