import {useRoutes} from 'react-router-dom'

import MainLayout from './components/layouts/mainLayout'
import Home from './pages/home'
import Doctors from './pages/doctors'
import DoctorPage from './pages/doctorPage'
import About from './pages/about'
import Contact from './pages/contact'
import Gallery from './pages/gallery'

const routeChildrenItem = (path, element, key, title) => {
    return {path, element, key, title}
}

const routeElement = (element, children) => {
    return {element, children}
}

const layouts = {
    main: routeElement(<MainLayout/>, [
        routeChildrenItem('/', <Home/>, 'home', 'Ana səhifə'),
        routeChildrenItem('/doctors', <Doctors/>, 'doctors', 'Həkimlərimiz'),
        routeChildrenItem('/doctors/:id', <DoctorPage/>, 'doctor', 'Həkim'),
        routeChildrenItem('/info', <About/>, 'about', 'Haqqımızda'),
        routeChildrenItem('/contact', <Contact/>, 'contact', 'Əlaqə'),
        routeChildrenItem('/gallery', <Gallery/>, 'gallery', 'Qalerya')
    ])
}

const layoutArr = Object.values(layouts)

const Router = () => {
    return useRoutes(layoutArr)
}

export default Router

export {
    layoutArr,
    layouts
}
